<app-loading-spinner mode="bar">
</app-loading-spinner>

<app-header-toolbar
  (sidenavOpenedChange)="sidenavStateToggle($event)"
  [currentUser]="currentUser"
  [isSidenavOpened]="true">
</app-header-toolbar>

<div class="home-page d-flex flex-fill justify-content-around">
  <mat-sidenav-container>
    <mat-sidenav
      position="end"
      (openedChange)="sidenavStateToggle($event)"
      [disableClose]="!isSmall"
      [mode]="'side'"
      [opened]="isSidenavOpened">

      <app-sidebar (sidenavOpenedChange)="sidenavStateToggle($event)">
      </app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="main main--with-margins">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- <app-snowfall></app-snowfall> -->
</div>
