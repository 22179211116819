<mat-toolbar
  class="main-toolbar mat-elevation-z2 mb-1"
  fxLayout
  fxLayoutAlign="space-between center">

  <a
    class="logo-link toolbar-top-item"
    [routerLink]="'/'">

    <img
      class="img"
      fxLayoutAlign="start center"
      alt="SPD Portal"
      [src]="'../assets/images/logo-2.svg'">
  </a>

  <div
    class="toolbar-top-item"
    fxLayoutAlign="end center"
    fxLayoutGap="1.5rem"
    fxLayoutGap.xs="1rem">

    <mat-icon
      svgIcon="bell-icon"
      [style.visibility]="'hidden'">
    </mat-icon>

    <button
      *appPermissions="{
            permissions: [ permissionsEnum.VIEW_NEWS_FEED ]
          }"
      mat-flat-button
      fxLayout="column"
      fxLayoutAlign="center center"
      (click)="redirectTo('/news-feed')">

      <mat-icon svgIcon="documents-icon">
      </mat-icon>

      <div class="button-label">News</div>

      <div
        *ngIf="currentDay < releasesPromotionDates['release-1-4']"
        class="new-feature"
        appPopup="From now the main SPD Group News can be seen here!">

        <app-new-feature-marker color="orange">
        </app-new-feature-marker>
      </div>
    </button>

    <button
      class="menu-button"
      mat-fab
      fxLayoutAlign="center center"
      [matMenuTriggerFor]="userMenu">

      <img
        *ngIf="currentUser?.id; else portalUserIcon"
        class="img-block"
        alt="{{currentUser?.firstName}} {{currentUser?.lastName}}"
        matTooltip="{{currentUser?.firstName}} {{currentUser?.lastName}}"
        [src]="currentUser?.profileUrl">
    </button>
  </div>
</mat-toolbar>

<mat-menu #userMenu>
  <button
    *appPermissions="{
            permissions: [ permissionsEnum.MANAGE_NEWS_FEED,  permissionsEnum.MANAGE_CAROUSEL ]
          }"
    class="toolbar-menu-item"
    mat-menu-item
    [matMenuTriggerFor]="portalSettings">

    Portal settings
  </button>

  <button
    class="toolbar-menu-item"
    mat-menu-item
    (click)="currentUser?.id ? logout() : login()">

    {{ currentUser?.id ? 'Log out' : 'Sign in' }}
  </button>
</mat-menu>

<mat-menu #portalSettings>
  <button
    *appPermissions="{
            permissions: [ permissionsEnum.MANAGE_CAROUSEL ]
          }"
    class="toolbar-menu-item"
    mat-menu-item
    routerLink="settings/manage-carousel">

    Manage carousel
  </button>

  <button
    *appPermissions="{
            permissions: [ permissionsEnum.MANAGE_NEWS_FEED ]
          }"
    class="toolbar-menu-item"
    mat-menu-item
    routerLink="settings/manage-news-feed">

    <div
      [isActivePopup]="currentDay < releasesPromotionDates['release-1-4']"
      appPopup="Here you can manage SPD Group News Feed">
      Manage news feed

      <app-new-feature-marker
        *ngIf="currentDay < releasesPromotionDates['release-1-4']"
        class="ml-1"
        color="orange">
      </app-new-feature-marker>
    </div>
  </button>
</mat-menu>

<ng-template #portalUserIcon>
  <mat-icon
    class="menu-icon"
    svgIcon="portal-user">
  </mat-icon>
</ng-template>
