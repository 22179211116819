import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SnowfallComponent } from './snowfall.component';

@NgModule({
  declarations: [ SnowfallComponent ],
  exports: [ SnowfallComponent ],
  imports: [ CommonModule ],
})
export class SnowfallModule {}
